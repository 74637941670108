import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import './FileUploader.scss';

const FileUploader = () => {
  const [articalNoList, setArticalNoList] = useState([]);
  const [color, setColor] = useState([]);
  useEffect(() => {
    if (!articalNoList.length) {
      api
        .get(`/products/all/articalNo/list`)
        .then((res) => {
          setArticalNoList(res.data);
        })
        .catch((err) => console.log(err));
    }
  });
  return (
    <div className='fileUploaderContainer'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.target);
          formData.append('shopId', 'Anarkali');
          api.post(`/products/update/img`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }}
      >
        <input type='file' name='image' id='file' />
        <label htmlFor='articalNo'>Artical No</label>
        <select
          onChange={(e) => {
            api
              .post(`/products/artical/data`, {
                articalNo: e.target.value,
                shopId: 'Anarkali',
              })
              .then((res) => {
                setColor(res.data);
              });
          }}
          name='ArticalNo'
          id='articalNo'
        >
          <option value=''>Select Artical No</option>
          {articalNoList.map((i, idx) => (
            <option key={idx} value={i.ArticalNo}>
              {i.ArticalNo}
            </option>
          ))}
        </select>
        <label htmlFor='color'>Color</label>
        <select name='Color' id='color'>
          <option value=''>Select Color</option>
          {color.map((i, idx) => (
            <option key={idx} value={i.Color}>
              {i.Color}
            </option>
          ))}
        </select>
        <button>Submit</button>
      </form>
    </div>
  );
};

export default FileUploader;
