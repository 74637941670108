import React from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import './Login.scss';

const Login = ({ setIsLogin }) => {
  const navigate = useNavigate();
  return (
    <div className='loginContainer'>
      <img src={require('../../assets/logo.jpg')} alt='' />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log(e, e.target.username.value);
          api
            .post('/auth/user', {
              username: e.target.username.value,
              password: e.target.password.value,
              shopId: 'Anarkali',
              role: 'Normal',
            })
            .then((res) => {
              if (res.data.status) {
                setIsLogin(true);
                navigate('/');
              } else {
                localStorage.clear();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        <label htmlFor='username'>Username</label>
        <input type='text' name='username' id='username' required />
        <label htmlFor='password'>Password</label>
        <input type='password' name='password' id='password' required />
        <button>Login</button>
      </form>
    </div>
  );
};

export default Login;
