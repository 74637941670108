import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import FileUploader from './pages/fileUploader';
import Login from './pages/login';
import RouteValidate from './utils/RouteValidate';

function App() {
  const [isLogin, setIsLogin] = useState(null);
  return (
    <Routes>
      <Route path='/login' element={<Login setIsLogin={setIsLogin} />} />
      <Route
        path='/'
        element={
          <RouteValidate isLogin={isLogin}>
            <FileUploader />
          </RouteValidate>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />} />
    </Routes>
  );
}

export default App;
